import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'


import "../components/layout.css"

export const AboutPageTemplate = ({  intro, gallery }) => (
         <div>
           <div>
             <div></div>
    </div>
    <section className="section section--gradient antyPadding">
             <div className="container">
        <div className="section AboutP antyPadding">
                 <div className="columns">
                   <div className="column is-10 is-offset-1 StoPro">
                     <div className="content">
                       <div className="columns">
                  <div className="column is-12">
                  </div>
                       </div>
                <div className="elooo alternatywka"><h3 className="has-text-weight-semibold is-size-2 headingCSS5">
                  {intro.heading}<hr className="hr"></hr>
                </h3>
                         <Features
                           gridItems={intro.blurbs}
                           key={intro.toString()}
                           href=""
                  />
                  <div className="descriptionTEXT">
                  <p className="descriptionTemat">Misja i wizja</p>
                    <p className="descriptionMisjaiWizja">Naszą misją jest:</p>


                    <p className="descriptionCSS">Satysfakcja klientów osiągnięta solidną pracą
                    przy racjonalnym gospodarowaniu posiadanymi środkami z wykorzystaniem nowoczesnych technologii produkcyjnych. <br></br> <br></br>

                      Wyznaczony cel realizujemy poprzez:</p><ul className="ListaKolo">
                      <li>ciągłą rozbudowę parku maszynowego,</li>
                      <li>poszerzanie oferty asortymentowej produkowanych wyrobów,</li>
                      <li>zatrudnienie wysoko wykwalifikowanych pracowników.</li>
                    </ul>

                    <p className="descriptionMisjaiWizja">Naszą wizją jest:</p>
                    <p className="descriptionCSS">Chcemy być cenioną i rozpoznawalną marką na krajowym rynku osprzętu dachowego i sukcesywnie zdobywać zaufanie odbiorców z zagranicy.<br></br> <br></br>

Główne atuty firmy Ekochron, które pomogą nam zrealizować tę wizję to:</p>
                    <ul className="ListaKolo" >
                      <li>kompleksowa obsługa,</li>
                      <li>wysoka jakość wyrobów,</li>
                      <li>krótkie terminy realizacji,</li>
                      <li>fachowa wiedza i bogate doświadczenie,</li>
                      <li>szybkie i elastyczne reagowanie na potrzeby klienta.</li>
                    </ul>
                    </div>
                       </div>
                       <div className="galeriaRIGHT galeriaRIGHTonas">

                         <div className="galeriaONAS">
                           <Features gridItems={gallery.blurbs} />
                         </div>
                       </div>
                       <div className="columns artykulyProd">
                         <div className="column is-12 has-text-centered">

                         </div>
                       </div>
                       <div className="column is-12 wpisy">
                         <h3 className="has-text-weight-semibold is-size-2">
                           Najnowsze wpisy:
                         </h3>
                         <BlogRoll />
                         <div className="column is-12 has-text-centered">
                           <Link className="btn" to="/blog">
                             Czytaj więcej
                           </Link>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </section>
         </div>
       );

AboutPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  gallery: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <AboutPageTemplate
        image={frontmatter.image}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        gallery={frontmatter.gallery}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default AboutPage

export const pageQuery = graphql`
         query AboutPageTemplate {
           markdownRemark(
             frontmatter: { templateKey: { eq: "about-page" } }
           ) {
             frontmatter {
               heading
               description
               subheading
               intro {
                 blurbs {
                   image {
                     childImageSharp {
                       fluid(maxWidth: 780, quality: 100) {
                         ...GatsbyImageSharpFluid
                       }
                     }
                   }
                   text
                 }

                 heading
                 description
               }
               gallery {
                 blurbs {
                   image {
                     childImageSharp {
                       fluid(maxWidth: 780, quality: 100) {
                         ...GatsbyImageSharpFluid
                       }
                     }
                   }

                 }

               }
             }
           }
         }
       `;
